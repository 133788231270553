import { SUPPORTED_LANGUAGES } from 'configs/common';
import { ESupportedLanguages } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';
import { Flex } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { GlobalOutlined } from '@ant-design/icons';

export const getLanguageMenu = (
	currentLanguage: ESupportedLanguages,
	handleLanguageChange: (newLang: ESupportedLanguages) => void
): ItemType => {
	const newLanguageConfig =
		currentLanguage === ESupportedLanguages.ENGLISH
			? SUPPORTED_LANGUAGES[ESupportedLanguages.ARABIC]
			: SUPPORTED_LANGUAGES[ESupportedLanguages.ENGLISH];

	return {
		key: 'change_language_cta',
		icon: <GlobalOutlined />,
		label: (
			<Flex
				gap='middle'
				align='center'
			>
				<TranslatedLabel i18nKey={`languages.${newLanguageConfig.iso_two_code}`} />{' '}
				<img
					src={newLanguageConfig.flag}
					alt='flag'
					loading='lazy'
					className='flag_img'
				/>
			</Flex>
		),
		onClick: () => {
			handleLanguageChange(newLanguageConfig.iso_two_code);
		},
	};
};
