import { FC, PropsWithChildren, Suspense } from 'react';
import { Outlet, RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { DEFAULT_BRANCH_ADMIN_ROUTES_CONFIG } from 'configs/routes';
import { CountryProvider } from 'hooks';
import { ABConfigProvider } from 'hooks/useABContext';
import { AppBrandProvider } from 'hooks/useAppBrandName';
import { GlobalConfigProvider } from 'hooks/useGlobalConfig';
import { ProtectedAccessByRoleWrapper, RequireAuthWrapper, UrlIdParamValidatorWrapper } from 'utils/appWrappers';
import { processRoutes } from 'utils/processRoutes';
import { EVendorUserType } from 'types/api';
import NewOrdersFetcher from './contexts/NewOrderFetcher';
import { GoogleMapsProvider } from 'contexts/GoogleMapsProvider';
import { AuthProvider } from 'contexts/auth';
import { SelectBranchDrawerProvider } from 'contexts/branchDrawer';
import { AppTitleProvider } from 'contexts/documentTitle';
import { GlobalRefreshTimerProvider } from 'contexts/globalRefreshTimer';
import NotificationProvider from 'contexts/notifications';
import Spinner from 'components/Spinner';
import ErrorPage from 'pages/ErrorPage';
import { Layout } from 'pages/Layout/Layout';
import Login from 'pages/Login';
import { notification } from 'antd';

// Antd configurations
notification.config({
	maxCount: 4,
});

const TranslationSuspenseSpinner: FC<PropsWithChildren> = (props) => (
	<Suspense
		fallback={<Spinner />}
		{...props}
	/>
);

const ROUTES_CONFIG = [
	{
		element: (
			<GlobalConfigProvider>
				<AppBrandProvider>
					<ABConfigProvider>
						<AuthProvider>
							<CountryProvider>
								<GlobalRefreshTimerProvider>
									<NotificationProvider>
										<SelectBranchDrawerProvider>
											<AppTitleProvider>
												<TranslationSuspenseSpinner>
													<Outlet />
												</TranslationSuspenseSpinner>
											</AppTitleProvider>
										</SelectBranchDrawerProvider>
									</NotificationProvider>
								</GlobalRefreshTimerProvider>
							</CountryProvider>
						</AuthProvider>
					</ABConfigProvider>
				</AppBrandProvider>
			</GlobalConfigProvider>
		),
		errorElement: (
			<ErrorPage title={`Sorry, the page you tried to visit isn't available.\nPlease, refresh the page.`} />
		),
		children: [
			{
				path: 'login',
				element: <Login />,
				children: [],
			},
			{
				path: '*',
				element: (
					<RequireAuthWrapper>
						<GoogleMapsProvider>
							<ProtectedAccessByRoleWrapper
								redirectToNoAccessRoute={false}
								allowRoles={[EVendorUserType.BRANCH_MANAGER, EVendorUserType.VENDOR_MANAGER]}
							>
								<NewOrdersFetcher />
							</ProtectedAccessByRoleWrapper>
							<Layout>
								<UrlIdParamValidatorWrapper />
							</Layout>
						</GoogleMapsProvider>
					</RequireAuthWrapper>
				),
				children: processRoutes(DEFAULT_BRANCH_ADMIN_ROUTES_CONFIG),
			},
		],
	},
];

const APP_ROUTER = createBrowserRouter(ROUTES_CONFIG as RouteObject[], { basename: process.env.PUBLIC_URL });

const App: FC = () => {
	return (
		<RouterProvider
			router={APP_ROUTER}
			fallbackElement={
				<Spinner
					defaultAntdSpinner
					hasOverlay={false}
				/>
			}
		/>
	);
};

export default App;
