import { TRouteObject } from 'configs/routes';
import { ProtectedAccessByRoleWrapper } from 'utils/appWrappers';
import { ProtectedAccessByCountryWrapper } from './ProtectedAccessByCountryWrapper';

export type TProcessRouteFunction = (route: TRouteObject) => TRouteObject | undefined;

// ? Applies functions in order by feeding results of previous function as input to the next
export const pipe = <T extends any[], U>(
	fn1: (...args: T) => U | undefined,
	...fns: Array<(a: U) => U | undefined>
) => {
	const piped = fns.reduce(
		(prevFn, nextFn) => (value: U) => {
			const prevResult = prevFn(value);

			if (prevResult) return nextFn(prevResult);

			return undefined;
		},
		(value: U | undefined) => (value ? value : undefined)
	);

	return (...args: T) => {
		const resultFn1 = fn1(...args);

		return resultFn1 ? piped(resultFn1) : undefined;
	};
};

export const traverseRouteTree = (
	origin: TRouteObject[],
	transformRoute: TProcessRouteFunction = (route) => route
): TRouteObject[] => {
	const transformedRoutes = origin.map((route) => {
		if (route?.children?.length) {
			route.children = traverseRouteTree(route.children, transformRoute);
		}

		return transformRoute(route);
	});

	// Filter out any undefined routes
	return transformedRoutes.filter((route): route is TRouteObject => route !== undefined);
};
// --

const addCountryWhiteListInterceptor: TProcessRouteFunction = (route: TRouteObject) => {
	if (route?.handle?.country_white_list?.length > 0) {
		route.element = (
			<ProtectedAccessByCountryWrapper country_white_list={route?.handle?.country_white_list}>
				{route.element}
			</ProtectedAccessByCountryWrapper>
		);
	}

	return route;
};

const addRoleProtectWrapper: TProcessRouteFunction = (route: TRouteObject) => {
	if (route?.handle?.roles?.length > 0) {
		route.element = (
			<ProtectedAccessByRoleWrapper allowRoles={route.handle.roles}>{route.element}</ProtectedAccessByRoleWrapper>
		);
	}

	return route;
};

export const processRoutes = (routes: TRouteObject[]) =>
	traverseRouteTree(
		routes,
		pipe(
			addRoleProtectWrapper,
			addCountryWhiteListInterceptor
			// Other
		)
	);
