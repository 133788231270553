import { Dispatch, FC, ReactNode, SetStateAction, createContext, useCallback, useEffect, useState } from 'react';
import { Location, RouteObject, To, matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { AUTH_API, AUTH_TOKEN_KEY, COUNTRY_API, REFRESH_TOKEN_KEY } from 'configs/api';
import { APP_LANGUAGE_STORAGE_KEY } from 'configs/common';
import { ABSOLUTE_ROUTES, DEFAULT_BRANCH_ADMIN_ROUTES_CONFIG } from 'configs/routes';
import { TRouteObject } from 'configs/routes';
import { useDispatch } from 'store';
import { setCountryList } from 'store/slices/countries';
import { setLanguage, setSelectedCountry } from 'store/slices/settings';
import { useGlobalConfigs, useHandleErrors } from 'hooks';
import { isAuthenticated, processClientLogout } from './helpers';
import { getAllowedRoutes } from 'utils/routes';
import { getAuthTokenFromLocalStorage, getRefreshTokenFromLocalStorage } from 'utils/storage';
import { getSelectedBranchesIdsFromLocalStorage } from 'utils/storage';
import { IBranchUserLogin, IBranchUserProfile, ICountry, IVendorAuth, IVendorUserAssociatedBranches } from 'types/api';
import { ESupportedLanguages, IListResponse, Nullable, TEmptyFunction } from 'types/common';
import { ESupportedCountriesIsoTwoCodes } from 'types/common';
import { AppError } from 'exceptions/AppError';

export interface IBranchUserAuthInfo extends IBranchUserProfile {
	access_token: string;
	refresh_token: string;
	//
	allowedRoutes: TRouteObject[];
	//
	stores: IVendorUserAssociatedBranches[];
	vendorId: number;
	vendors: IVendorAuth[];
}

interface IAuthContextData {
	user: Nullable<IBranchUserAuthInfo>;
	signin: (username: string, password: string) => Promise<void>;
	signout: TEmptyFunction;
	isSigningOut: boolean;
	selectedBranchIdList: number[];
	setSelectedBranchIdList: Dispatch<SetStateAction<number[]>>;

	isAuthenticated: () => boolean;
}

export const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

export const AuthProvider: FC<{ children?: ReactNode }> = ({ children }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const { handleError } = useHandleErrors();
	const { http, storage } = useGlobalConfigs();

	// ! states
	const [isSigningOut, setIsSigningOut] = useState(false);
	const [user, setUser] = useState<Nullable<IBranchUserAuthInfo>>(null);
	const [selectedBranchIdList, setSelectedBranchIdList] = useState<number[]>([]);

	// ! helpers
	const fetchCountries = (): Promise<Array<ICountry>> => {
		return http(COUNTRY_API.getList())
			.then((response: AxiosResponse<Array<ICountry>>) => {
				return response.data;
			})
			.catch((error) => {
				handleError(new AppError(error));
				return [];
			});
	};

	const fetchAccessibleStores = (): Promise<IVendorUserAssociatedBranches[]> =>
		http(AUTH_API.getAccessibleStores(''))
			.then((response: AxiosResponse<IListResponse<IVendorUserAssociatedBranches>>) => {
				// process stores
				return response.data.data;
			})
			.catch((error) => {
				handleError(new AppError(error));
				return [];
			});

	const fetchAccessibleVendors = (): Promise<IVendorAuth[]> =>
		http(AUTH_API.getAccessibleVendors())
			.then((response: AxiosResponse<IListResponse<IVendorAuth>>) => {
				// process vendors
				return response.data.data;
			})
			.catch((error) => {
				handleError(new AppError(error));
				return [];
			});

	const processUserData = useCallback(
		async ({ user, access_token, refresh_token }: IBranchUserLogin): Promise<IBranchUserAuthInfo> => {
			const { type, language, country_id } = user;

			// * storage tokens
			storage.set(AUTH_TOKEN_KEY, access_token);
			storage.set(REFRESH_TOKEN_KEY, refresh_token);

			// * fetch vendors, stores and countries data for the specific user
			const [vendors, stores, countries] = await Promise.all([
				fetchAccessibleVendors(),
				fetchAccessibleStores(),
				fetchCountries(),
			]);

			// * multiple branch
			const localSavedBranches = getSelectedBranchesIdsFromLocalStorage(storage);
			const branchIntersection = stores?.filter(
				(store) => localSavedBranches.length === 0 || localSavedBranches.includes(store.id)
			);

			setSelectedBranchIdList(branchIntersection.map((store) => store.id));

			// * countries
			dispatch(setCountryList(countries));
			dispatch(setSelectedCountry(country_id));

			// * language
			const localStorageLanguage = localStorage.getItem(
				APP_LANGUAGE_STORAGE_KEY
			) as Nullable<ESupportedLanguages>;
			const newLanguage = localStorageLanguage ?? language;
			dispatch(setLanguage(newLanguage));

			const currentCountry = countries.find((country) => country.id === country_id)!;
			const allowedRoutes = getAllowedRoutes(
				type,
				DEFAULT_BRANCH_ADMIN_ROUTES_CONFIG,
				currentCountry.iso_two_code as ESupportedCountriesIsoTwoCodes
			);

			// * return user info
			return {
				...user,
				//
				access_token,
				refresh_token,
				//
				allowedRoutes,
				//
				stores,
				vendorId: vendors[0].id,
				vendors,
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const findRedirectRoute = (location: Location, allowedRoutes: TRouteObject[] = []): To => {
		// remove the general '*' path, to avoid an all match case
		allowedRoutes = allowedRoutes.filter((route) => route.path !== '*');

		// find a match
		const routeMatches = matchRoutes(allowedRoutes as RouteObject[], location);
		if (routeMatches) {
			return location;
		}

		// if don't match, navigate to first allowed route
		return allowedRoutes[0].path || ABSOLUTE_ROUTES.NO_ACCESS_ROUTE;
	};

	const navigateAfterAuthenticate = (userAllowedRoutes: TRouteObject[]) => {
		if (!userAllowedRoutes?.length) return;

		const to = findRedirectRoute(location, userAllowedRoutes);

		navigate(to, { replace: true });
	};

	const profile = async (ctrl: AbortController) => {
		const requestConfig = { ...AUTH_API.profile(), signal: ctrl.signal };

		http(requestConfig)
			.then(({ data }: AxiosResponse<IBranchUserProfile>) =>
				processUserData({
					user: data,
					access_token: getAuthTokenFromLocalStorage(storage),
					refresh_token: getRefreshTokenFromLocalStorage(storage),
				})
			)
			.then((userInfo: IBranchUserAuthInfo) => {
				setUser(userInfo);
				navigateAfterAuthenticate(userInfo.allowedRoutes);
			})
			.catch((error) => {
				handleError(new AppError(error.data, error.status));
			});
	};

	// ! useEffects

	useEffect(() => {
		if (isAuthenticated(storage)) {
			const ctrl = new AbortController();
			profile(ctrl);
			// Unsubscribing
			return () => ctrl.abort();
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// ! api functions

	const signin = (email: string, password: string) => {
		const requestConfig = AUTH_API.login(email, password);

		return http(requestConfig)
			.then(({ data }: AxiosResponse<IBranchUserLogin>) => processUserData(data))
			.then((userInfo: IBranchUserAuthInfo) => {
				setUser(userInfo);
				navigateAfterAuthenticate(userInfo.allowedRoutes);
			})
			.catch((error) => {
				handleError(error, true);
				return Promise.reject(error);
			});
	};

	const signout = () => {
		setIsSigningOut(true);

		http(AUTH_API.logout())
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				setIsSigningOut(false);
				// clear user auth data
				setUser(null);
				processClientLogout(storage, navigate, dispatch);
			});
	};

	// ! return
	const authData: IAuthContextData = {
		user,
		signin,
		signout,
		isSigningOut,
		selectedBranchIdList,
		setSelectedBranchIdList,
		isAuthenticated: () => isAuthenticated(storage),
	};

	return <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>;
};
