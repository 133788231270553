import { EOrderWalletComplementAmountRate, IOrder } from 'types/api';
import { IProductsTableSummary } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import { Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export const getTableSummary = ({ payment, promotions_used }: IOrder): IProductsTableSummary[] => {
	const translationPrefix = 'order_details.tabs.details.product_details.summary';

	const defaultRows = [
		{
			label: (
				<TranslatedLabel
					nameSpace='orders'
					i18nKey={`${translationPrefix}.item_total`}
				/>
			),
			value: payment.total_item_cost,
		},
		{
			label: (
				<Space size={6}>
					<TranslatedLabel
						nameSpace='orders'
						i18nKey={`${translationPrefix}.discount`}
					/>
					<Tooltip
						title={
							<TranslatedLabel
								nameSpace='orders'
								extra={{ promotions: promotions_used?.vendor }}
								i18nKey={`order_details.tabs.details.product_details.summary.${
									!promotions_used?.vendor.length ? 'tooltip_no_promotion' : 'tooltip_promotions_used'
								}`}
							/>
						}
					>
						<InfoCircleOutlined style={{ color: 'grey' }} />
					</Tooltip>
				</Space>
			),
			value: payment.store_discount_amount,
		},
		{
			label: (
				<Space size={6}>
					<TranslatedLabel
						nameSpace='orders'
						i18nKey={`${translationPrefix}.promotion_discount`}
					/>
					<Tooltip
						title={
							<TranslatedLabel
								nameSpace='orders'
								extra={{ promotions: promotions_used?.platform }}
								i18nKey={`${translationPrefix}.${
									!promotions_used?.platform.length
										? 'tooltip_no_promotion'
										: 'tooltip_promotions_used'
								}`}
							/>
						}
					>
						<InfoCircleOutlined style={{ color: 'grey' }} />
					</Tooltip>
				</Space>
			),
			value: payment.platform_discount_amount,
		},
		{
			label: (
				<TranslatedLabel
					nameSpace='orders'
					i18nKey={`${translationPrefix}.delivery_charge`}
				/>
			),
			value: payment.delivery_cost,
		},
	];

	const partialPaymentRows = [
		{
			label: (
				<TranslatedLabel
					nameSpace='orders'
					i18nKey='order_details.tabs.details.product_details.summary.wallet_credit_applied'
				/>
			),
			value: `${payment.wallet_complement_amount} ${payment.currency_code}`,
		},
		{
			label: (
				<TranslatedLabel
					nameSpace='orders'
					i18nKey='order_details.tabs.details.product_details.summary.total_pay'
				/>
			),
			value: `${payment.amount_payable} ${payment.currency_code}`,
		},
	];

	const totalOrderRow = {
		label: (
			<TranslatedLabel
				nameSpace='orders'
				i18nKey='order_details.tabs.details.product_details.summary.total_order'
			/>
		),
		extraInfo: payment.tax_description,
		value: `${payment.grand_total} ${payment.currency_code}`,
	};

	// ! return
	const tableRows: IProductsTableSummary[] = [...defaultRows];

	if (payment.wallet_complement_amount_rate === EOrderWalletComplementAmountRate.PARTIAL) {
		tableRows.push(...partialPaymentRows);
	}

	tableRows.push(totalOrderRow);

	return tableRows;
};
