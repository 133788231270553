import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getIsRTL } from 'store/selectors';
import { useGlobalConfigs } from 'hooks';
import { IPromotionListResponse } from 'types/api';
import { TEmptyFunction } from 'types/common';
import { promotion_target_config } from '../types';
import TranslatedLabel from 'components/TranslatedLabel';
import { PROMOTION_TARGET_ICON_CONFIGS } from 'pages/Campaigns/config';
import styles from './RecommendationCard.module.css';
import { Button, Card, Flex, Space, Typography } from 'antd';
import { ArrowRightOutlined, HeartOutlined } from '@ant-design/icons';
import { TOKEN_CONFIG_BY_BRAND } from 'brand-config';

interface IRecommendationCardProps {
	campaign: IPromotionListResponse;
	call_to_action_text: string;
	callToActionCallback: TEmptyFunction;
}

const CTAIconButton: FC = () => {
	const isRTL = useSelector(getIsRTL);

	return <ArrowRightOutlined rotate={isRTL ? 180 : 0} />;
};

const RecommendationCard: FC<IRecommendationCardProps> = ({ callToActionCallback, call_to_action_text, campaign }) => {
	const { t: tCampaigns } = useTranslation('campaigns');
	const isRTL = useSelector(getIsRTL);
	const { configs } = useGlobalConfigs();

	const displayTitle =
		(!isRTL ? campaign.info.description : campaign.info.description_ar) ||
		(!isRTL ? campaign.info.title : campaign.info.title_ar);

	return (
		<div className={styles.recommended_card}>
			{campaign.authors.recommender && (
				<div
					key={campaign.id}
					className={styles.recommended_tag}
					style={{ backgroundColor: TOKEN_CONFIG_BY_BRAND[configs.brand].colorPrimary }}
				>
					<HeartOutlined />

					{tCampaigns('recommended.influencer', { influencer_name: campaign.authors.recommender.name })}
				</div>
			)}
			<Card styles={{ body: { padding: 24 } }}>
				<Space
					direction='vertical'
					size='middle'
					className='w-100'
				>
					<div className={styles.title}>
						<Flex
							justify='center'
							align='center'
							className={styles.recommend_icon}
							style={{
								color: promotion_target_config[campaign.configurations.target].color,
								backgroundColor: promotion_target_config[campaign.configurations.target].background,
							}}
						>
							{PROMOTION_TARGET_ICON_CONFIGS[campaign.configurations.target]}
						</Flex>

						<TranslatedLabel
							nameSpace='campaigns'
							i18nKey={`enums.target.${campaign.configurations.target}`}
						/>
					</div>
					<Typography.Text type='secondary'>{displayTitle}</Typography.Text>
					<Button
						className='w-100'
						style={{ color: TOKEN_CONFIG_BY_BRAND[configs.brand].colorPrimary }}
						onClick={callToActionCallback}
					>
						{call_to_action_text}

						<CTAIconButton />
					</Button>
				</Space>
			</Card>
		</div>
	);
};

export default RecommendationCard;
