import { createSlice } from '@reduxjs/toolkit';
import { ICountry } from 'types/api';
import { IActionArg, IDefaultStateFields } from '../types';

// ! initial state
export interface ICountriesSlice extends IDefaultStateFields {
	countriesList: ICountry[];
}

const countriesSliceInitialState: ICountriesSlice = {
	countriesList: [],
	loading: false,
};

// ! slice
const countriesSlice = createSlice({
	name: 'countriesSlice',
	initialState: countriesSliceInitialState,
	reducers: {
		setCountryList: (state, action: IActionArg<Array<ICountry>>) => {
			state.countriesList = action.payload;
		},
	},
	extraReducers: () => {},
});

export const { setCountryList } = countriesSlice.actions;

export default countriesSlice.reducer;
