import { createSelector } from '@reduxjs/toolkit';
import { reduceSelectedCountryDataToCountryCodeOptionsList } from 'store/helpers';
import { ICountriesSlice } from 'store/slices/countries';
import { ESupportedCountriesIsoTwoCodes } from 'types/common';
import { ISelector } from '../types';
import { getSettingsSlice } from './settings';

export const getCountrySlice: ISelector<void, ICountriesSlice> = (state) => state.countries;

export const getSelectedCountry = createSelector(
	[getCountrySlice, getSettingsSlice],
	({ countriesList }, { selectedCountryId }) => {
		const selectedCountry = countriesList.find((country) => country.id === selectedCountryId);

		return (
			selectedCountry && {
				...selectedCountry,
				selectedCountryTimezone: selectedCountry?.timezones?.[0]?.name,
			}
		);
	}
);

export const getSelectedCountryCurrency = createSelector([getSelectedCountry], (selectedCountryItem) => {
	return selectedCountryItem?.currency_code ?? '';
});

export const getSelectedCountryIsoTwoCode = createSelector([getSelectedCountry], (selectedCountryItem) => {
	return selectedCountryItem?.iso_two_code.toLowerCase() ?? '';
});

export const getCountryCodeOptionsList = createSelector([getSelectedCountry], (selectedCountryItem) => {
	if (!selectedCountryItem) {
		return [];
	}

	return reduceSelectedCountryDataToCountryCodeOptionsList(selectedCountryItem);
});

export const isSaudiArabiaCountry = createSelector([getSelectedCountryIsoTwoCode], (isoTwoCode) => {
	return isoTwoCode === ESupportedCountriesIsoTwoCodes.SAUDI_ARABIA;
});
