import { ReactNode } from 'react';
import { ITimeRangeSliderOption } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import dayjs, { Dayjs } from 'dayjs';
import { Space } from 'antd';

const range = (startValue: number, endValue: number) => {
	return Array.apply(0, Array(endValue - startValue)).map((_, index) => index + startValue);
};

export const getCampaignDateDiffInMilliseconds = (end_date: string | Dayjs, start_date: string | Dayjs) => {
	return dayjs.duration(dayjs(end_date).diff(dayjs(start_date)));
};

export const formatDateCampaignDate = (dayjsDuration: plugin.Duration): ReactNode => {
	const sTime = dayjsDuration.asSeconds();

	let minutes = Math.floor(sTime / 60);
	let hours = Math.floor(minutes / 60);
	let days = Math.floor(hours / 24);

	let timeDiffHours = hours - days * 24;
	const remainingMinutes = minutes % 60;

	let resultingString: ReactNode[] = [];

	if (days) {
		resultingString.push(
			days,
			<TranslatedLabel
				i18nKey='time_units.day'
				extra={{ count: days }}
			/>
		);
	}

	if (timeDiffHours) {
		resultingString.push(
			timeDiffHours,
			<TranslatedLabel
				i18nKey='time_units.hour'
				extra={{ count: timeDiffHours }}
			/>
		);
	}

	if (remainingMinutes && (timeDiffHours || remainingMinutes)) {
		resultingString.push(
			remainingMinutes,
			<TranslatedLabel
				i18nKey='time_units.minute'
				extra={{ count: remainingMinutes }}
			/>
		);
	}

	return resultingString.length ? (
		<Space
			size='small'
			wrap
		>
			{resultingString.map((a, index) => (
				<span key={index}>{a}</span>
			))}
		</Space>
	) : null;
};

export const TIME_RANGE_MARKS_S = [
	...range(1, 24).map((el) => dayjs.duration(el, 'hours').asSeconds()),
	...range(1, 31).map((el) => dayjs.duration(el, 'days').asSeconds()),
];

export const NEW_TIME_RANGE_MARKS: Record<number, ITimeRangeSliderOption> = TIME_RANGE_MARKS_S.map(
	(seconds, index) => ({
		key: index,
		label: <>{formatDateCampaignDate(dayjs.duration(seconds, 'seconds'))}</>,
		value: seconds,
	})
);

export const NEW_TIME_RANGE_MARKS_LENGTH = Object.entries(NEW_TIME_RANGE_MARKS).length - 1;
