import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { INVOICES_API } from 'configs/api';
import { useVendor } from 'hooks';
import { transformIInvoiceToTableData } from './helper';
import { downloadFile } from 'utils/downloadFile';
import { IInvoice } from 'types/api';
import { EInvoiceListType, TInvoiceTable } from './types';
import ActionsMenu, { IActionMenuItem, getActionButtonsColumnWidth } from 'components/ActionsMenu';
import TableWrapper from 'components/Table';
import { INVOICE_LIST_CONFIG } from './config';
import { ColumnsType } from 'antd/es/table';
import { SaveOutlined } from '@ant-design/icons';

export const InvoiceList: FC = () => {
	const { t: tCommon } = useTranslation('common');

	const { vendorId } = useVendor();

	const currentConfig = useMemo(
		() => INVOICE_LIST_CONFIG[vendorId ? EInvoiceListType.VENDOR_PAGE : EInvoiceListType.GLOBAL_PAGE],
		[vendorId]
	);

	// ! handlers
	const downloadInvoice = (invoice: TInvoiceTable) => {
		downloadFile(invoice.url, invoice.name);
	};

	// ! useMemos
	const tableRequestConfig = useMemo(
		() => INVOICES_API.list({ vendor_id: vendorId ? [vendorId] : undefined }),
		[vendorId]
	);

	const columns: ColumnsType<TInvoiceTable> = useMemo(
		() => {
			const columnList = [...currentConfig.defaultColumns];

			// actions
			columnList.push({
				key: 'actions',
				title: tCommon('table.headers.actions'),
				width: getActionButtonsColumnWidth(3),
				render: (_, invoice) => {
					const actions: IActionMenuItem[] = [
						{
							type: 'button',
							title: tCommon('action_buttons.download'),
							icon: <SaveOutlined />,
							disabled: !invoice.url,
							actionCb: () => downloadInvoice(invoice),
						},
					];

					return <ActionsMenu actions={actions} />;
				},
			});

			return columnList;
		},
		[] // eslint-disable-line react-hooks/exhaustive-deps
	);

	// ! render
	return (
		<TableWrapper<TInvoiceTable, IInvoice>
			scrollX={'max-content'}
			pageTitle={currentConfig.pageTitle}
			columns={columns}
			requestConfig={tableRequestConfig}
			defaultControlSizes={currentConfig.defaultControlSizes}
			transformDataToTableData={transformIInvoiceToTableData}
		/>
	);
};
