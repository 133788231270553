import { ChangeEvent, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { calculateTableHeight } from './configs';
import { THttpRequestParams, useCustomSearchParams, useHttpClient } from 'hooks';
import {
	applyColumnConfigItemFilters,
	applyColumnConfigItemSorter,
	getOnlyPrefixedParams,
	removeEmptySearchParams,
} from './helpers';
import { IListResponse, Nullable } from 'types/common';
import { IBaseTableData, ITableWrapperProps } from './types';
import { useListParamsSetter } from 'components/ListParamsRouteWrapper';
import ErrorPage from 'pages/ErrorPage';
import styles from './Table.module.css';
import { Button, Input, Space, Table, Tooltip } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/es/table/interface';
import { PageHeader } from '@ant-design/pro-layout';

export const TableWrapper = <T extends IBaseTableData, OriginData>({
	searchParamsPrefix,
	disallowVerticalScroll,
	searchParam = 'search',
	defaultControlSizes: inputSizes = 'large',
	showResetFiltersButton = true,
	preventSearchRequest,
	...props
}: ITableWrapperProps<T, OriginData>) => {
	const { t: tCommon } = useTranslation('common');

	const [data, setData] = useState<T[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [searchInputValue, setSearchInputValue] = useState('');
	const { request, error, isLoading } = useHttpClient<IListResponse<OriginData>>();

	const [params, groupedParams, setParams] = useCustomSearchParams(
		props.defaultParams ? props.defaultParams : LIST_DEFAULT_PARAMS
	);
	useListParamsSetter(groupedParams, setParams);

	const tableRef = useRef<HTMLDivElement>(null);
	const requestTimerRef = useRef<Nullable<NodeJS.Timer>>(null);

	const [tableHeight, setTableHeight] = useState<number | undefined>(600);
	const tableSearchParamPrefix = searchParamsPrefix ? searchParamsPrefix + '__' : '';

	const searchPlaceholder = useMemo(() => {
		return props.searchPlaceholder?.length ? props.searchPlaceholder : tCommon('table.wrapper.search_placeholder');
	}, [props.searchPlaceholder, tCommon]);

	// ! memos
	// apply url search params to filters and sorter in table config to render them in table
	const tableColumnsConfigWithFilters = useMemo(() => {
		return props.columns.map((columnConfigItem) => {
			if (!columnConfigItem.key) return columnConfigItem;

			const configWithSorter = applyColumnConfigItemSorter(columnConfigItem, params);

			return applyColumnConfigItemFilters(configWithSorter, params);
		});
	}, [props.columns, params]);

	// * scrollY calculations
	const scrollY = useMemo(
		() => (disallowVerticalScroll || !tableHeight ? undefined : tableHeight),
		[disallowVerticalScroll, tableHeight]
	);

	// ! useEffects
	useEffect(() => {
		if (preventSearchRequest && preventSearchRequest(searchInputValue)) {
			return;
		}

		const ctrl = new AbortController();

		let argumentParams = groupedParams;

		// remove empty params before request
		argumentParams = removeEmptySearchParams(argumentParams);

		if (searchParamsPrefix) {
			argumentParams = getOnlyPrefixedParams(groupedParams, tableSearchParamPrefix);
		}

		const requestConfig = { ...props.requestConfig };
		requestConfig.params = Object.assign({}, props.requestConfig.params, argumentParams);

		// Fetch data
		if (requestTimerRef.current) clearTimeout(requestTimerRef.current);

		requestTimerRef.current = setTimeout(() => {
			request(
				{
					requestConfig,
					successCallback: (listResponse) => {
						let newData = listResponse.data.map(props.transformDataToTableData);

						setData(newData);
						setTotal(listResponse.count);

						if (props.onDataChange) props.onDataChange(listResponse);
					},
				},
				ctrl.signal
			);
		}, 30);

		// Unsubscribing (cancel the request) and removing timeout for debounce
		return () => {
			if (requestTimerRef.current) clearTimeout(requestTimerRef.current);

			ctrl.abort();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params, props.refetchData, props.requestConfig]);

	// set input value based on search param changes
	useEffect(() => {
		const searchParamValue = getParam(searchParam);

		setSearchInputValue(searchParamValue || '');
	}, [params, searchParam]); // eslint-disable-line react-hooks/exhaustive-deps

	useLayoutEffect(() => {
		if (disallowVerticalScroll || isLoading) return;

		const tableHeight = calculateTableHeight(tableRef.current);

		setTableHeight(tableHeight);
	}, [tableRef, isLoading, disallowVerticalScroll]);

	const getParam = (key: string) => {
		return params.get(tableSearchParamPrefix + key);
	};

	// ! handlers
	const handleSearch = (value: string) => {
		const newSearchParams: THttpRequestParams = {
			...groupedParams,
			[tableSearchParamPrefix + 'page']: '1',
			[tableSearchParamPrefix + searchParam]: value,
		};
		if (!value.length) delete newSearchParams[searchParam];

		setParams(newSearchParams);
	};

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<T> | SorterResult<T>[],
		{ action }: TableCurrentDataSource<T>
	) => {
		const { columnKey, order } = sorter as SorterResult<T>;

		let updatedParams: Record<string, string | string[] | FilterValue | null | undefined> = {
			...groupedParams,
		};

		if (action === 'paginate') {
			updatedParams = {
				...updatedParams,
				[tableSearchParamPrefix + 'page']: `${pagination.current}`,
				[tableSearchParamPrefix + 'per_page']: `${pagination.pageSize}`,
			};
		}

		if (action === 'sort') {
			updatedParams = {
				...updatedParams,
				[tableSearchParamPrefix + 'sort_by']: order ? columnKey?.toString() : null,
				[tableSearchParamPrefix + 'order_by']: order ? (order === 'ascend' ? 'ASC' : 'DESC') : null,
			};
		}

		if (action === 'filter') {
			updatedParams = {
				...updatedParams,
				...filters,
				[tableSearchParamPrefix + 'page']: '1', // reset always the pagination
			};
		}

		// clean 'null' and 'undefined' values from newParams object
		for (const key in updatedParams) {
			if (updatedParams[key] === null || updatedParams[key] === undefined) {
				delete updatedParams[key];
			}
		}

		setParams(updatedParams as THttpRequestParams);
	};

	const onResetFiltersClick = () => {
		const initialParams = props.defaultParams ? props.defaultParams : LIST_DEFAULT_PARAMS;
		setParams(new URLSearchParams(initialParams as Record<string, string>));
	};

	const onSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchInputValue(e.target.value);
	};

	// ! render
	if (error && !axios.isCancel(error)) {
		return <ErrorPage />;
	}

	return (
		<>
			{(props.pageTitle || props.headerExtraActions) && (
				<PageHeader
					title={props.pageTitle}
					extra={props.headerExtraActions}
					onBack={props.onTitleBack}
				/>
			)}

			<div className={styles.table_actions_wrapper}>
				{(!props.disableSearch || !!props.tableExtraActions) && (
					<Space
						className={styles.table_extra_actions_wrapper}
						style={{ marginBottom: props.disableSearch ? '15px' : 0 }}
					>
						{!props.disableSearch && (
							<Input.Search
								allowClear
								disabled={!data.length && !getParam(searchParam)}
								size={inputSizes}
								placeholder={searchPlaceholder}
								className={styles.search_input}
								defaultValue={getParam(searchParam) ?? ''}
								value={searchInputValue}
								onChange={onSearchInputChange}
								onSearch={handleSearch}
							/>
						)}

						{!!props.tableExtraActions && (
							<div className={styles.extra_actions}>{props.tableExtraActions}</div>
						)}
					</Space>
				)}

				{showResetFiltersButton && (
					<Tooltip title={tCommon('table.wrapper.reset_filters_tooltip')}>
						<Button
							className={styles.reset_filters_btn}
							size={inputSizes}
							onClick={onResetFiltersClick}
						>
							{tCommon('table.wrapper.reset_filters_btn')}
						</Button>
					</Tooltip>
				)}
			</div>

			<div ref={tableRef}>
				<Table<T>
					bordered
					tableLayout='fixed'
					scroll={{ y: scrollY, x: props.scrollX }}
					loading={isLoading}
					dataSource={data}
					columns={tableColumnsConfigWithFilters}
					pagination={{
						total: total,
						current: +(getParam('page') ?? LIST_DEFAULT_PARAMS.page),
						pageSize: +(getParam('per_page') ?? LIST_DEFAULT_PARAMS.per_page),
						showSizeChanger: true,
					}}
					onChange={handleTableChange}
					rowSelection={props.rowSelection}
				/>
			</div>
		</>
	);
};
