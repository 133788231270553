import { CSSProperties, FC, PropsWithChildren } from 'react';
import styles from './CustomDrawer.module.css';
import { Drawer, DrawerProps } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const CustomDrawer: FC<DrawerProps & PropsWithChildren> = ({ children, ...props }) => {
	const headerStyles: CSSProperties = {
		border: 'none',
		paddingTop: 40,
		paddingBottom: 20,
	};
	const bodyStyles: CSSProperties = { paddingTop: 0 };
	const footerStyles: CSSProperties = {
		border: 'none',
		paddingBottom: 56,
		paddingTop: 16,
		paddingLeft: 24,
		paddingRight: 24,
	};

	return (
		<Drawer
			className={styles.custom_drawer}
			styles={{
				header: headerStyles,
				body: bodyStyles,
				footer: footerStyles,
			}}
			closeIcon={<CloseOutlined />}
			{...props}
		>
			{children}
		</Drawer>
	);
};

export default CustomDrawer;
