import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { VENDOR_BRANCH_API } from 'configs/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { errorCase, pendingCase } from 'store/helpers';
import { THttpFunction } from 'utils/axiosInstance';
import { THandleError } from 'utils/handleError';
import { IBranch } from 'types/api';
import { Nullable } from 'types/common';
import { IActionArg, IDefaultStateFields } from '../types';

// ! initial state
export interface IBranchSlice extends IDefaultStateFields {
	data: Nullable<IBranch>;
	error: Nullable<any>;
}

const branchSliceInitialState: IBranchSlice = {
	// status
	data: null,

	// own state
	error: null,
	loading: false,
};

export const fetchBranchInformation = createAsyncThunk<
	IBranch,
	{
		http: THttpFunction;
		vendorId: number;
		branchId: number;
		handleError: THandleError;
		signal?: AbortSignal;
	},
	{ state: RootState }
>(
	'branchSlice/fetchBranch',
	async (
		{
			http,
			vendorId,
			branchId,
			handleError,
			signal,
		}: {
			http: THttpFunction;
			vendorId: number;
			branchId: number;
			handleError: THandleError;
			signal?: AbortSignal;
		},
		{ rejectWithValue }
	) => {
		if (branchId === undefined) return rejectWithValue(branchId);

		try {
			const requestConfig: AxiosRequestConfig = VENDOR_BRANCH_API.get(vendorId, branchId);
			requestConfig.signal = signal;
			const prom: AxiosResponse<IBranch> = await http(requestConfig);

			return prom.data;
		} catch (error) {
			handleError(error, true);
			return rejectWithValue(error);
		}
	},
	{
		condition: (_, { getState }) => {
			const { branch } = getState();

			if (branch.loading) {
				// Already fetched or in progress, don't need to re-fetch
				return false;
			}
		},
	}
);

// ! slice
const branchSlice = createSlice({
	name: 'branchSlice',
	initialState: branchSliceInitialState,
	reducers: {
		setData: (state, action: IActionArg<Nullable<IBranch>>) => {
			state.data = action.payload;
		},

		cleanup: (state) => {
			state = { ...branchSliceInitialState };
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchBranchInformation.pending, (state, { payload }) => {
				state.error = null;
				state.data = null;
				pendingCase(state);
			})
			.addCase(fetchBranchInformation.rejected, (state, { payload }) => {
				state.error = payload;
				errorCase(state);
			})
			.addCase(fetchBranchInformation.fulfilled, (state, { payload }) => {
				state.data = payload;
				state.loading = false;
			});
	},
});

export const { setData, cleanup } = branchSlice.actions;

export default branchSlice.reducer;
