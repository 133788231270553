import { NavigateFunction } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { AppDispatch } from 'store';
import { EHttpStatus } from 'types/api';
import { IStorageUtils } from './storage/storage';
import { AppError } from 'exceptions/AppError';
import { processClientLogout } from 'contexts/auth/helpers';
import { MessageInstance } from 'antd/es/message/interface';

const DEFAULT_ERROR_MESSAGE = 'Sorry, something went wrong!';
const UNAUTHORIZED_ERROR_MESSAGE = 'Unauthorized';

export type THandleError = (error: AxiosResponse | AppError, displayErrorMsg?: boolean) => void;

export const handleError =
	(
		storage: IStorageUtils,
		navigate: NavigateFunction,
		message: MessageInstance,
		dispatch: AppDispatch
	): THandleError =>
	(error: AxiosResponse | AppError, displayErrorMsg?: boolean) => {
		const status = error?.status;
		const errorMessage: string = error?.data?.message || DEFAULT_ERROR_MESSAGE;

		const isClientAppError = AppError.isError(error);
		const isUnauthorized = status === EHttpStatus.UNAUTHORIZED;

		// Access and refresh tokens were expired and this is not custom error
		if (isClientAppError || isUnauthorized) {
			const unauthorizedErrorMessage = isUnauthorized ? UNAUTHORIZED_ERROR_MESSAGE : errorMessage;
			processClientLogout(storage, navigate, dispatch);

			message.error(unauthorizedErrorMessage);
			return;
		}

		if (displayErrorMsg) message.error(errorMessage, 5);

		console.warn('There was an error!', error);
	};
