import { FC, PropsWithChildren, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'store';
import { fetchIntegrationSettings } from 'store/slices/integrations';
import { useGlobalConfigs, useHandleErrors } from 'hooks';

export const IntegrationConfigLoader: FC<PropsWithChildren> = ({ children = <Outlet /> }) => {
	const dispatch = useDispatch();
	const { http } = useGlobalConfigs();
	const { handleError } = useHandleErrors();

	// ! useEffects
	useEffect(() => {
		dispatch(
			fetchIntegrationSettings({
				http,
				handleError,
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <>{children}</>;
};
