import { THttpRequestConfig, THttpRequestParams } from 'hooks/useHttpClient';
import { IOptionGroupsSavePayload, IProductDetailsPayload, IVariantBulkSavePayload } from 'types/api';
import { EStatus } from 'types/common';

// ! Products
export const VENDOR_PRODUCT_API = {
	fetchList: (vendorId: number, params: THttpRequestParams = {}): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendorId}/products`,
		method: 'GET',
		params,
	}),
	createProduct: (vendorId: number, data: IProductDetailsPayload): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendorId}/products`,
		method: 'POST',
		data,
	}),
	getProduct: (vendorId: number, productId: number): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendorId}/products/${productId}`,
		method: 'GET',
	}),
	patchProduct: (
		vendorId: number,
		productId: number,
		data: Partial<IProductDetailsPayload> | { status: EStatus }
	): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendorId}/products/${productId}`,
		method: 'PATCH',
		data,
	}),
	deleteProduct: (vendorId: number, productId: number): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendorId}/products/${productId}`,
		method: 'DELETE',
	}),
	updateProductImage: (vendorId: number, productId: number, data: FormData): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendorId}/products/${productId}/images`,
		method: 'POST',
		data,
	}),
	getOptionGroupsList:
		(vendorId: number) =>
		(search?: string): THttpRequestConfig => ({
			url: `/vendor/vendors/${vendorId}/products/option-groups`,
			method: 'GET',
			params: {
				search,
			},
		}),
	bulkSaveVariants: (vendorId: number, productId: number, data: IVariantBulkSavePayload): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendorId}/products/${productId}/variants`,
		method: 'POST',
		data,
	}),
	bulkSaveOptionGroups: (
		vendorId: number,
		productId: number,
		data: IOptionGroupsSavePayload
	): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendorId}/products/${productId}/option-groups`,
		method: 'POST',
		data,
	}),
	bulkOperations: {
		getBulkOperationInsertProductTemplate: (vendorId: number): THttpRequestConfig => ({
			url: `/vendor/vendors/${vendorId}/products/products-and-variants/insert-template`,
			method: 'GET',
		}),
		getBulkOperationUpdateProductTemplate: (vendorId: number): THttpRequestConfig => ({
			url: `/vendor/vendors/${vendorId}/products/products-and-variants/update-template`,
			method: 'GET',
		}),
		getBulkOperationInsertOptionsTemplate: (vendorId: number): THttpRequestConfig => ({
			url: `/vendor/vendors/${vendorId}/products/products-options/insert-template`,
			method: 'GET',
		}),
		getBulkOperationUpdateOptionsTemplate: (vendorId: number): THttpRequestConfig => ({
			url: `/vendor/vendors/${vendorId}/products/products-options/update-template`,
			method: 'GET',
		}),
		getBulkOperationInsertProductsConfig: (
			vendorId: string | number,
			data: any // TODO: review type
		): THttpRequestConfig => ({
			url: `/vendor/vendors/${vendorId}/products/products-and-variants/bulk`,
			method: 'POST',
			data,
		}),
		getBulkOperationUpdateProductsConfig: (
			vendorId: string | number,
			data: any // TODO: review type
		): THttpRequestConfig => ({
			url: `/vendor/vendors/${vendorId}/products/products-and-variants/bulk`,
			method: 'PATCH',
			data,
		}),
		getBulkOperationBulkInsertProductOptionGroupsAndOptionsConfig: (
			vendorId: string | number,
			data: any // TODO: review type
		): THttpRequestConfig => ({
			url: `/vendor/vendors/${vendorId}/products/products-options/bulk`,
			method: 'POST',
			data,
		}),
		getBulkOperationBulkUpdateProductOptionGroupsAndOptionsConfig: (
			vendorId: string | number,
			data: any // TODO: review type
		): THttpRequestConfig => ({
			url: `/vendor/vendors/${vendorId}/products/products-options/bulk`,
			method: 'PATCH',
			data,
		}),
	},
};

export const VENDOR_PRODUCT_SNAPSHOT_API = {
	getProduct: (
		vendorId: number,
		branchId: number,
		menuId: number,
		productId: number,
		categoryId: number,
		subCategoryId?: number
	): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendorId}/stores/${branchId}/menus/${menuId}/products/${productId}`,
		params: {
			category_id: categoryId,
			sub_category_id: subCategoryId,
		},
		method: 'GET',
	}),
	patchProduct: (
		vendorId: number,
		branchId: number,
		menuId: number,
		productId: number,
		categoryId: number,
		data: Partial<IProductDetailsPayload> | { status: EStatus },
		subCategoryId?: number
	): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendorId}/stores/${branchId}/menus/${menuId}/products/${productId}`,
		params: {
			category_id: categoryId,
			sub_category_id: subCategoryId,
		},
		method: 'PATCH',
		data,
	}),
	resetPrice: (
		vendorId: number,
		branchId: number,
		menuId: number,
		productId: number,
		categoryId: number,
		subCategoryId?: number
	): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendorId}/stores/${branchId}/menus/${menuId}/products/${productId}/reset-price`,
		method: 'PATCH',
		params: {
			category_id: categoryId,
			sub_category_id: subCategoryId,
		},
	}),
	bulkSaveVariants: (
		vendorId: number,
		branchId: number,
		menuId: number,
		productId: number,
		categoryId: number,
		data: IVariantBulkSavePayload,
		subCategoryId?: number
	): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendorId}/stores/${branchId}/menus/${menuId}/products/${productId}/variants`,
		params: {
			category_id: categoryId,
			sub_category_id: subCategoryId,
		},
		method: 'POST',
		data,
	}),
	bulkSaveOptionGroups: (
		vendorId: number,
		branchId: number,
		menuId: number,
		productId: number,
		categoryId: number,
		data: IOptionGroupsSavePayload,
		subCategoryId?: number
	): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendorId}/stores/${branchId}/menus/${menuId}/products/${productId}/option-groups`,
		params: {
			category_id: categoryId,
			sub_category_id: subCategoryId,
		},
		method: 'POST',
		data,
	}),
};
