import { TRouteObject } from 'configs/routes';
import { TProcessRouteFunction, pipe, traverseRouteTree } from 'utils/processRoutes';
import { EVendorUserType } from 'types/api';
import { ESupportedCountriesIsoTwoCodes } from 'types/common';

const filterByRoles =
	(role: EVendorUserType): TProcessRouteFunction =>
	(route: TRouteObject) => {
		if (!route.handle?.roles?.length || route.handle.roles.includes(role)) {
			return route;
		}

		return undefined;
	};
const filterRouteByCountry =
	(selectedCountryIsoTwoCode: ESupportedCountriesIsoTwoCodes): TProcessRouteFunction =>
	(route: TRouteObject) => {
		if (!(route?.handle?.country_white_list?.length > 0)) {
			return route;
		}

		if (route?.handle?.country_white_list?.includes(selectedCountryIsoTwoCode)) {
			return route;
		}

		return undefined;
	};

export const getAllowedRoutes = (
	role: EVendorUserType,
	routes: TRouteObject[],
	selectedCountryIsoTwoCode: ESupportedCountriesIsoTwoCodes
) => {
	return traverseRouteTree(
		routes as TRouteObject[],
		pipe(
			filterByRoles(role),
			filterRouteByCountry(selectedCountryIsoTwoCode)
			//
		)
	);
};
