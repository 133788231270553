import { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { INavMenuRoute } from 'configs/routes';

export const NavLinkLabelWrapper: FC<{ route: INavMenuRoute; children: ReactNode }> = ({
	route: { path, defaultListParams },
	children,
}): JSX.Element => {
	const finalPath = defaultListParams ? `${path}?${new URLSearchParams(defaultListParams)}` : path!;

	return <NavLink to={finalPath}>{children}</NavLink>;
};
