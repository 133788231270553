import { APP_LANGUAGE_STORAGE_KEY, TIMEZONE_STORAGE_KEY } from 'configs/common';
import { createSlice } from '@reduxjs/toolkit';
import { UTC_TIME_ZONE } from 'hooks/useLocaleTimeUtils/config';
import { IStorageUtils } from 'utils/storage';
import { ESupportedLanguages, Nullable } from 'types/common';
import { IActionArg, IDefaultStateFields } from '../types';

// ! initial state
export interface ISettingsSlice extends IDefaultStateFields {
	// global
	language: ESupportedLanguages;
	selectedCountryId: Nullable<number>;
	selectedTimezone: string;
}

const settingsSliceInitialState: ISettingsSlice = {
	// global
	language: (localStorage.getItem('i18nextLng') as ESupportedLanguages) ?? ESupportedLanguages.ENGLISH,
	selectedCountryId: null,
	selectedTimezone: UTC_TIME_ZONE,

	// own state
	loading: false,
};

const settingsSlice = createSlice({
	name: 'settingsSlice',
	initialState: settingsSliceInitialState,
	reducers: {
		setLanguage: (state, action: IActionArg<ESupportedLanguages>) => {
			state.language = action.payload;
			localStorage.setItem(APP_LANGUAGE_STORAGE_KEY, state.language);
		},
		setSelectedCountry: (state, { payload: newSelectedCountryId }: IActionArg<number>) => {
			state.selectedCountryId = newSelectedCountryId;
		},

		setSelectedTimezone: (
			state,
			{ payload }: IActionArg<{ newSelectedTimezone: string; storage: IStorageUtils }>
		) => {
			const { newSelectedTimezone, storage } = payload;

			state.selectedTimezone = newSelectedTimezone;
			storage.set(TIMEZONE_STORAGE_KEY, newSelectedTimezone);
		},
	},
});

export const { setLanguage, setSelectedCountry, setSelectedTimezone } = settingsSlice.actions;

export default settingsSlice.reducer;
