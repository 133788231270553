import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticatedInformation, useBranchDrawer, useGlobalRefreshTimerContext } from 'hooks';
import { getTableKey, transformVendorsDataToTableData } from './helper';
import { debounce } from 'utils/debounce';
import { EVendorUserType, IVendorUserAssociatedBranches } from 'types/api';
import { EBranchSelectionTableData, IBranchSelectionTableData } from './types';
import CustomDrawer from 'components/CustomDrawer';
import styles from './SelectBranchDrawer.module.css';
import { SELECT_BRANCH_DRAWER_TABLE_COLUMNS } from './config';
import { Button, Checkbox, Input, Space, Table, Typography } from 'antd';
import { UniqueIdentifier } from '@dnd-kit/core';

export const SelectBranchDrawer: FC = () => {
	const { onRefresh } = useGlobalRefreshTimerContext();
	const { user, selectedBranchIdList, setSelectedBranchIdList } = useAuthenticatedInformation();
	const { openSelectBranchesDrawer, closeDrawer } = useBranchDrawer();
	const [filteredBranches, setFilteredBranches] = useState(user.stores);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const { t: tBranches } = useTranslation('branches', { keyPrefix: 'drawer' });

	const dataSource: IBranchSelectionTableData[] = useMemo(() => {
		let tableData = transformVendorsDataToTableData(user.vendors, filteredBranches);

		if (user.type !== EVendorUserType.VENDOR_MANAGER) {
			tableData = tableData.filter((elem: IBranchSelectionTableData) => elem.children?.length);
		}

		if (tableData.length === 1 && tableData[0].type === EBranchSelectionTableData.VENDOR && tableData[0].children) {
			// if only has access to one vendor display directly the stores at first level
			return tableData[0].children;
		}

		return tableData;
	}, [filteredBranches, user.type, user.vendors]);

	const isAllChecked = useMemo(
		() => user.vendors.every((vendor) => selectedRowKeys.includes(vendor.id.toString())),
		[selectedRowKeys, user.vendors]
	);

	// ! effects
	useEffect(() => {
		setFilteredBranches(user.stores);
	}, [user.stores]);

	useEffect(() => {
		if (openSelectBranchesDrawer) {
			const selectedRowKeys = user.stores
				.filter((store) => selectedBranchIdList.includes(store.id))
				.map((store) => `${store.vendor_id}-${store.id}`);

			setSelectedRowKeys(selectedRowKeys);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openSelectBranchesDrawer]);

	const selectedLength = selectedRowKeys.length;
	const isIndeterminate = useMemo(() => selectedLength > 0 && !isAllChecked, [isAllChecked, selectedLength]);

	// ! handlers
	const handleSearch = (searchedString: string) => {
		const userBranches: IVendorUserAssociatedBranches[] = user.stores;

		if (searchedString === '') {
			setFilteredBranches(userBranches);
		} else {
			const lowerSearchString: string = searchedString.toLocaleLowerCase();
			const filteredBranches: IVendorUserAssociatedBranches[] = userBranches.filter(
				({ name, name_ar, id }) =>
					name?.toLocaleLowerCase().includes(lowerSearchString) ||
					name_ar?.toLocaleLowerCase().includes(lowerSearchString) ||
					id.toString().includes(lowerSearchString)
			);
			setFilteredBranches(filteredBranches);
		}
	};

	const debouncedHandleSearch = debounce(handleSearch, 400);

	const onChangeAllBranches = () => {
		let newSelected: UniqueIdentifier[] = [];

		if (!isAllChecked) {
			newSelected = user.vendors.map((vendor) => vendor.id.toString());

			newSelected = user.stores.map(({ vendor_id, id }) => getTableKey(vendor_id, id));

			newSelected = newSelected.concat(user.vendors.map(({ id }) => getTableKey(id)));
		}

		handleChangeSelectedBranch(newSelected);
	};

	const handleChangeSelectedBranch = (newSelectedRowKeys: React.Key[]) => {
		const selectedBranchesList: Record<UniqueIdentifier, true> = {};
		const selectedVendorList: Record<UniqueIdentifier, true> = {};

		newSelectedRowKeys.forEach((key) => {
			const regexMatch = key.toString().match(/(\d+)-(\d+)/);

			if (regexMatch) {
				selectedVendorList[regexMatch[1]] = true; // Vendor Id
				selectedBranchesList[regexMatch[2]] = true; // Branch Id
			} else {
				selectedVendorList[key.toString()] = true; // Vendor Id
			}
		});

		setSelectedBranchIdList(Object.keys(selectedBranchesList).map((key) => +key));

		setSelectedRowKeys(newSelectedRowKeys);

		onRefresh();
	};

	const handleCloseDrawer = () => {
		if (selectedRowKeys.length === 0) return;

		closeDrawer();
	};

	const handleRowSelection = (record: IBranchSelectionTableData) => {
		setSelectedRowKeys((prev) => {
			let newSelectedRows = [...prev];

			const hashMapActiveKey = newSelectedRows.reduce<Record<UniqueIdentifier, true>>((acc, elem) => {
				acc[elem.toString()] = true;

				return acc;
			}, {});

			const recordKey = getTableKey(record.vendorId, record.branchId);

			if (hashMapActiveKey[recordKey]) {
				// clicked element is selected
				delete hashMapActiveKey[record.vendorId];
				delete hashMapActiveKey[recordKey];
				if (!record.branchId) {
					// vendor row => remove all branches
					record.children?.forEach((element) => {
						delete hashMapActiveKey[getTableKey(element.vendorId, element.branchId)];
					});
				}
			} else {
				hashMapActiveKey[recordKey] = true;

				if (!record.branchId) {
					// vendor row => adds all related branches

					record.children?.forEach((element) => {
						hashMapActiveKey[getTableKey(element.vendorId, element.branchId)] = true;
					});
				}
			}

			const newList = Object.keys(hashMapActiveKey);
			handleChangeSelectedBranch(newList);
			return newList;
		});
	};

	// ! render
	return (
		<CustomDrawer
			title={tBranches('title')}
			placement='right'
			size='large'
			open={openSelectBranchesDrawer}
			onClose={handleCloseDrawer}
		>
			<Space
				direction='vertical'
				size='middle'
				style={{ display: 'flex' }}
			>
				<Input.Search
					placeholder={tBranches('search_placeholder')}
					onSearch={handleSearch}
					onChange={(e) => debouncedHandleSearch(e.target.value)}
				/>
				<div>
					<Space
						size='large'
						className='w-100'
						style={{ padding: 20, cursor: 'pointer' }}
						onClick={() => onChangeAllBranches()}
					>
						<Checkbox
							checked={isAllChecked}
							indeterminate={isIndeterminate}
							onChange={onChangeAllBranches}
						/>
						{!isAllChecked || isIndeterminate ? tBranches('select.all') : tBranches('select.none')}
					</Space>

					<Table<IBranchSelectionTableData>
						showHeader={false}
						bordered={false}
						columns={SELECT_BRANCH_DRAWER_TABLE_COLUMNS}
						dataSource={dataSource}
						pagination={false}
						rowClassName={styles.table_row}
						onRow={(record: IBranchSelectionTableData) => ({
							onClick: () => {
								handleRowSelection(record);
							},
						})}
						rowSelection={{
							onChange: (selectedRowKeys) => {
								handleChangeSelectedBranch(selectedRowKeys);
								setSelectedRowKeys(selectedRowKeys);
							},
							checkStrictly: false,
							selectedRowKeys,
							fixed: true,
						}}
					/>
				</div>

				<Space
					direction='vertical'
					className='flex-center'
				>
					{selectedLength === 0 && (
						<Typography.Text type='danger'>{tBranches('warning_no_selection')}</Typography.Text>
					)}

					<Button
						onClick={handleCloseDrawer}
						disabled={selectedLength === 0}
					>
						{tBranches('apply')}
					</Button>
				</Space>
			</Space>
		</CustomDrawer>
	);
};
