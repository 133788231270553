import { FC, useEffect } from 'react';
import { ORDERS_API } from 'configs/api';
import { useHttpClient } from 'hooks';
import { IOrderLog } from 'types/api';
import { IListResponse } from 'types/common';
import useOrderContext from 'pages/Orders/useOrderContext';
import OrderTimeline from './OrderTimeline';
import { Card } from 'antd';

const TimelineTab: FC = () => {
	const { orderId } = useOrderContext();

	// ! http clients
	const { response, request: getOrderHistoryRequest, isLoading } = useHttpClient<IListResponse<IOrderLog>>();

	// ! useEffects
	useEffect(() => {
		const ctrl = new AbortController();

		getOrderHistoryRequest(
			{
				requestConfig: ORDERS_API.getHistory(orderId),
			},
			ctrl.signal
		);

		return () => {
			ctrl.abort();
		};
	}, [orderId]); // eslint-disable-line react-hooks/exhaustive-deps

	// ! render
	return (
		<Card
			key='timeline-info'
			loading={isLoading}
		>
			<OrderTimeline items={response?.data} />
		</Card>
	);
};

export default TimelineTab;
