import { AUTH_TOKEN_KEY, REFRESH_TOKEN_URL } from 'configs/api';
import { SELECTED_BRANCH_ID_STORAGE_KEY } from 'configs/common';
import { IStorageUtils } from './storage';
import * as yup from 'yup';

export const getRefreshTokenFromLocalStorage = (storage: IStorageUtils): string => {
	const yupSchema = yup.string().required();

	try {
		let token = storage.get(REFRESH_TOKEN_URL);

		return yupSchema.validateSync(token) ?? '';
	} catch (error) {
		return '';
	}
};

export const getAuthTokenFromLocalStorage = (storage: IStorageUtils): string => {
	const yupSchema = yup.string().required();

	try {
		let token = storage.get(AUTH_TOKEN_KEY);

		return yupSchema.validateSync(token) ?? '';
	} catch (error) {
		return '';
	}
};

export const getSelectedBranchesIdsFromLocalStorage = (storage: IStorageUtils): number[] => {
	const yupSchema = yup.array().of(yup.number().required());

	try {
		let token = storage.get(SELECTED_BRANCH_ID_STORAGE_KEY);

		return yupSchema.validateSync(token) ?? [];
	} catch (error) {
		return [];
	}
};
