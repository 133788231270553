import { useEffect, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import { SEARCH_DEBOUNCE_TIMER } from 'configs/common';
import { useGlobalConfigs, useHandleErrors } from 'hooks';
import { DEFAULT_USER_INTERACTION_DEBOUNCE, debounce } from 'utils/debounce';
import { IListResponse, Nullable } from 'types/common';
import { THttpRequestConfig, THttpRequestParams } from './useHttpClient';

interface ISearchDataListProps {
	configHttp: (search?: string, params?: THttpRequestParams) => THttpRequestConfig;
	initialValues?: Nullable<string[]>;
	searchRequestOnlyOnDemand?: Nullable<boolean>;
}

export function useSearchDataList<IDataType = object>({
	configHttp,
	initialValues,
	searchRequestOnlyOnDemand,
}: ISearchDataListProps) {
	const { http } = useGlobalConfigs();

	const { handleError } = useHandleErrors();

	// ! state
	const [total, setTotal] = useState<number>();
	const [list, setList] = useState<IDataType[]>([]);
	const [loading, setLoading] = useState(false);
	const timerRef = useRef<Nullable<NodeJS.Timer>>(null);

	// ! helpers
	const fetchData = async (config: THttpRequestConfig): Promise<Nullable<Array<IDataType>>> => {
		try {
			setLoading(true);
			const res: AxiosResponse<IListResponse<IDataType>> = await http(config);
			setTotal(res?.data?.count);
			return res?.data?.data || null;
		} catch (error) {
			handleError(error, true);
			return null;
		} finally {
			setLoading(false);
		}
	};

	// ! handlers
	const onSearch = async (search: string, params?: THttpRequestParams) => {
		if (!loading) setLoading(true);
		if (timerRef.current) clearTimeout(timerRef.current);

		timerRef.current = setTimeout(async () => {
			const updatedList = await fetchData(configHttp(search, params));

			setList(updatedList || []);
			setLoading(false);
		}, SEARCH_DEBOUNCE_TIMER);
	};

	const cleanup = () => {
		setList([]);
		setTotal(undefined);
	};

	// ! effects
	useEffect(() => {
		if (!searchRequestOnlyOnDemand) {
			onSearch('', { ids: initialValues ?? [] });
		}
	}, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(
		() => () => {
			if (timerRef.current) clearTimeout(timerRef.current);
		},
		[]
	);

	const debouncedSearch = debounce(onSearch, DEFAULT_USER_INTERACTION_DEBOUNCE);

	// ! result
	return {
		list,
		total,
		loading,
		onSearch: debouncedSearch,
		cleanup,
	};
}
