import { THttpRequestConfig, THttpRequestParams } from 'hooks/useHttpClient';
import { EShiftOrigin, IBranchesAssociationPayload, IWorkShiftTiming } from 'types/api';

export const VENDOR_WORK_SHIFTS_API = {
	fetchList: (vendorId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/shifts`,
		method: 'GET',
	}),
	// ! CRUD
	create: (vendorId: number, data: { name: string; name_ar: string }): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/shifts`,
		method: 'POST',
		data,
	}),
	get: (vendorId: number, shiftId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/shifts/${shiftId}`,
		method: 'GET',
	}),
	update: (vendorId: number, shiftId: number, data: { name: string; name_ar: string }): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/shifts/${shiftId}`,
		method: 'PATCH',
		data,
	}),
	delete: (vendorId: number, shiftId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/shifts/${shiftId}`,
		method: 'DELETE',
	}),

	// timings
	getTiming: (vendorId: number, shiftId: number): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/shifts/${shiftId}/timings`,
		method: 'GET',
	}),
	postTiming: (vendorId: number, shiftId: number, data: IWorkShiftTiming): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/shifts/${shiftId}/timings`,
		method: 'POST',
		data,
	}),
	resetWorkShiftToIntegration: (vendorId: number, source: EShiftOrigin): THttpRequestConfig => ({
		url: `/vendor/vendors/${vendorId}/shifts/management`,
		method: 'POST',
		data: { source },
	}),

	// Branches
	getBranches: (vendorId: number, shiftId: number, params?: THttpRequestParams): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/shifts/${shiftId}/associable-branches`,
		method: 'GET',
		params,
	}),
	getUnassociatedBranches: (vendorId: number, params?: THttpRequestParams): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/shifts/unassociated-branches`,
		method: 'GET',
		params,
	}),
	associateToBranches: (
		vendorId: number,
		shiftId: number,
		data: IBranchesAssociationPayload
	): THttpRequestConfig => ({
		url: `vendor/vendors/${vendorId}/shifts/${shiftId}/branches`,
		method: 'POST',
		data,
	}),
};
