import { NavigateFunction } from 'react-router-dom';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { AppDispatch } from 'store';
import { AUTH_LOGOUT_ACTION } from 'store/rootReducer';
import { IStorageUtils, getAuthTokenFromLocalStorage } from 'utils/storage';

export const isAuthenticated = (storage: IStorageUtils): boolean => {
	const token = getAuthTokenFromLocalStorage(storage);

	return !!(token && token.length > 0);
};

export const processClientLogout = (storage: IStorageUtils, navigate: NavigateFunction, dispatch: AppDispatch) => {
	storage.clear();
	dispatch({ type: AUTH_LOGOUT_ACTION });

	// Go to Login page
	navigate(ABSOLUTE_ROUTES.LOGIN, {});
};
