import { FC, PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { getVendorIntegrationType } from 'store/selectors';
import { fetchVendorInformation } from 'store/slices/vendor';
import { useAuthenticatedInformation, useGlobalConfigs, useHandleErrors } from 'hooks';
import { IVendor } from 'types/api';
import { Nullable, TEmptyFunction } from 'types/common';

interface IVendorContextData {
	vendorId: number;
	data: Nullable<IVendor>;
	error: any;
	isLoading: boolean;

	vendorIntegrationType: Nullable<string>;
	//
	fetchData: TEmptyFunction;
}

export const VendorContext = createContext<IVendorContextData>({} as IVendorContextData);

const useVendor = () => {
	return useContext(VendorContext);
};

interface IVendorProviderProps extends PropsWithChildren {
	preventMountFetch?: boolean;
	vendorId?: number;
}

const VendorProvider: FC<IVendorProviderProps> = ({
	preventMountFetch = false,
	vendorId: vendorIdArg,
	children = <Outlet />,
}) => {
	const { user } = useAuthenticatedInformation();
	const { http } = useGlobalConfigs();
	const { handleError } = useHandleErrors();
	const dispatch = useDispatch();

	const { vendorId: VendorIdParameter } = useParams();

	// ! selectors
	const data = useSelector((state) => state.vendor.data);
	const error = useSelector((state) => state.vendor.error);
	const isLoading = useSelector((state) => state.vendor.loading);

	const vendorIntegrationType = useSelector(getVendorIntegrationType);

	// ! memos
	const vendorId = useMemo(
		() => vendorIdArg ?? +(VendorIdParameter ?? user.vendorId),
		[VendorIdParameter, user.vendorId, vendorIdArg]
	);

	// ! handlers
	const fetchData = () => {
		if (!vendorId) return;

		dispatch(fetchVendorInformation({ http, vendorId: +vendorId, handleError }));
	};

	// ! useEffects

	useEffect(() => {
		if (!preventMountFetch) {
			fetchData();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vendorId]);

	// ! render
	if (!vendorId) return null;

	const vendorData: IVendorContextData = {
		vendorId,
		data,
		error,
		isLoading,

		// ! handlers
		fetchData,
		vendorIntegrationType,
	};

	return <VendorContext.Provider value={vendorData}>{children}</VendorContext.Provider>;
};

export { useVendor, VendorProvider };
