import { useCallback, useState } from 'react';
import { NavLink, generatePath } from 'react-router-dom';
import { INavMenuRoute } from 'configs/routes';
import { useSelector } from 'store';
import { getSettingsSlice } from 'store/selectors';
import { useAuthenticatedInformation } from 'hooks';
import { IVendorAuth } from 'types/api';
import { ESupportedLanguages } from 'types/common';
import { InputSearchVendors } from './components/InputSearchVendors';
import { NavContentLabel } from './components/NavContentLabel';
import { NavLinkLabelWrapper } from './components/NavLinkLabelWrapper';

// TODO: Review
type TNaveItem = {
	route: INavMenuRoute;
	item: any;
	translationKey?: string;
	detailsPath: string;
	key: string;
};

export function useNavVendor() {
	const { user } = useAuthenticatedInformation();
	const { language } = useSelector(getSettingsSlice);

	const [filteredVendors, setFilteredVendors] = useState<IVendorAuth[]>(user.vendors);

	const handleVendorSearch = useCallback(
		(searchedString: string) => {
			let filteredVendors = user.vendors;

			if (searchedString?.length) {
				const loweredCaseSearch = searchedString.toLowerCase();

				filteredVendors = filteredVendors.filter(
					({ id, name }) =>
						name?.toLowerCase().includes(loweredCaseSearch) || id.toString().includes(loweredCaseSearch)
				);
			}

			setFilteredVendors(filteredVendors);
		},
		[user.vendors]
	);

	const createVendorSearchInput = (keySuffix: string) => ({
		key: `search-vendors-${keySuffix}`,
		label: <InputSearchVendors handleVendorSearch={handleVendorSearch} />,
	});

	const createNavLink = (vendors: IVendorAuth, detailsPath: string, key: string) => ({
		key: `${key}/${vendors.id}`,
		label: (
			<NavLink
				to={generatePath(detailsPath, {
					vendorId: vendors.id,
				})}
			>{`#${vendors.id} ${language === ESupportedLanguages.ARABIC ? vendors.name_ar : vendors.name}`}</NavLink>
		),
	});

	const generateNavItem = ({ route, item, translationKey, detailsPath, key }: TNaveItem) => {
		if (user.vendors.length === 1) {
			route.path = generatePath(detailsPath, {
				vendorId: user.vendors[0].id,
			});

			item.label = (
				<NavLinkLabelWrapper route={route}>
					<NavContentLabel translationKey={translationKey} />
				</NavLinkLabelWrapper>
			);
		} else {
			item.label = <NavContentLabel translationKey={translationKey} />;

			item.children = [
				createVendorSearchInput(key),
				...filteredVendors.map((vendor) => createNavLink(vendor, detailsPath, key)),
			];
		}
		return item;
	};

	return { filteredVendors, generateNavItem };
}
