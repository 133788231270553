import { ReactNode } from 'react';
import {
	EOrderBookingTypes,
	EOrderDeliveryStatus,
	EOrderDisputedStatus,
	EOrderPaymentMethods,
	EOrderPaymentStatus,
	EOrderStatus,
	EOrderType,
	IDispatcherOrderBranch,
	IOrderActions,
	IOrderMetrics,
} from 'types/api';
import { EDeliveryMode } from 'types/common';
import { IDispatcherOrderTimelineStep } from './OrdersCards/DispatcherOrderTimeline';

// ! types & enums
export enum EDispatcherTab {
	SCHEDULED = 'SCHEDULED',
	NEW = 'NEW',
	PREPARING = 'PREPARING',
	READY_FOR_PICK_UP = 'READY_FOR_PICK_UP',
	DISPATCHED = 'DISPATCHED',
	COMPLETED = 'COMPLETED',
	CANCELLED = 'CANCELLED',
}

export const EDispatcherTabsTranslationKeys = Object.entries(EDispatcherTab).reduce<Record<EDispatcherTab, string>>(
	(acc, [key, value]) => {
		acc[value as EDispatcherTab] = key.toLowerCase();
		return acc;
	},
	{} as Record<EDispatcherTab, string>
);

export enum EDispatcherOrderCardActionsBtnKeys {
	CAN_TRANSITION_TO_NEW = 'can_transition_to_new',
	CAN_PREPARE = 'can_prepare',
	CAN_MARK_AS_READY = 'can_mark_as_ready',
	CAN_COMPLETE = 'can_complete',
	CAN_CANCEL = 'can_cancel',
	CAN_REJECT = 'can_reject',
}

export declare type TDispatcherTabs = keyof typeof EDispatcherTab;

// ! interfaces
export interface IDispatcherTabConfig {
	key: EDispatcherTab;
	filter: Partial<IBranchDispatcherOrderListFilterRequest>;
	extractMetricFn: (metrics: IOrderMetrics) => number | undefined;
}

export interface IOrderCardData {
	vendorId: number;
	branch: IDispatcherOrderBranch;
	status: EOrderStatus;
	status_description: string;
	order_id: number;
	order_code: string;
	order_type: EOrderType;
	order_placed_at: string;
	order_new_at: string;
	order_paid_at: string;
	order_list: string;
	payment_type: EOrderPaymentMethods;
	payment_type_icon: ReactNode;
	payment_status: EOrderPaymentStatus;
	payment_should_display_payment_method: boolean;
	to_pay: string;
	total_order: string;
	booking_type: EOrderBookingTypes;
	pickup_time?: string;
	pickup_address: string;
	pickup_address_latlng: string;
	delivery_status: EOrderDeliveryStatus;
	delivery_address: string;
	delivery_address_latlng: string;
	driver_id?: number;
	driver_name?: string;
	timeline: IDispatcherOrderTimelineStep[];
	actions: IOrderActions;
	delivery_mode: EDeliveryMode;
	delivery_mode_description: string;
	delivery_mode_description_detailed: string;
	min_customer_delivery_eta_at: string;
	max_customer_delivery_eta_at: string;
}

export interface IOrderingReq {
	sort_by?: string;
	order_by?: string;
}
export interface IPaginationReq {
	page?: string;
	per_page?: string;
}

export interface IBranchDispatcherOrderListFilterRequest extends IPaginationReq, IOrderingReq {
	start_date?: string;
	end_date?: string;
	status?: EOrderStatus | EOrderStatus[];
	delivery_status?: EOrderDeliveryStatus | EOrderDeliveryStatus[];
	booking_type?: EOrderBookingTypes;
	dispute_status?: EOrderDisputedStatus | EOrderDisputedStatus[];
	ringable?: boolean;
}
