import { Nullable } from 'types/common';
import { LAYOUT_CONTENT_ID } from 'pages/Layout/configs';

export const DEFAULT_TABLE_HEADER_HEIGHT = 55;
export const DEFAULT_TABLE_PAGINATOR_HEIGHT = 90;

export const calculateTableHeight = (tableElement: Nullable<HTMLDivElement>) => {
	if (!tableElement) return;

	const { top } = tableElement.getBoundingClientRect();
	const pageContainer = tableElement.closest(`#${LAYOUT_CONTENT_ID}`);

	if (!pageContainer) return;

	const tableHeaderHeight = tableElement.querySelector('thead')?.clientHeight ?? 0;
	const pageContainerTop = pageContainer?.getBoundingClientRect().top ?? 0;

	const topHeight = top - pageContainerTop;
	const pageContainerHeight = pageContainer.clientHeight ?? 0;

	const newHeight = pageContainerHeight - tableHeaderHeight - topHeight - DEFAULT_TABLE_PAGINATOR_HEIGHT;

	const tableBodyHeight = tableElement.querySelector('tbody')?.clientHeight ?? 0;

	return tableBodyHeight > newHeight ? newHeight : undefined;
};
