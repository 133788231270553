import { EDeliveryMode, EUserTypes } from 'types/common';

// ! enums
export enum EOrderStatus {
	SCHEDULED = 90,
	NEW = 100,
	APPROVED_BY_STORE = 110,
	PREPARING = 120,
	READY = 130,
	DISPATCHED = 140,
	COMPLETED = 150,
	REJECTED = 151,
	CANCELLED = 152,
	FAILED = 153,
	AWAITING_PAYMENT = 154,
	INTERVENTION = 155,
	UNCONFIRMED = 156,
}

export enum EOrderDeliveryStatus {
	SCHEDULED = 90,
	// ! preparing
	DRIVER_NOT_ASSIGNED = 100, // PENDING, old value: 0
	DRIVER_IS_ASSIGNED = 110, // old value: 1
	DRIVER_HEADING_TO_BRANCH = 120, // old value: 11
	DRIVER_ARRIVED_AT_BRANCH = 130, // old value: 3
	// ! ready
	ORDER_PICKED_UP = 140, // old value: 104
	// ! dispatched
	DRIVER_ON_ROUTE_TO_CUSTOMER = 150, // ORDER_PICKED_UP_AND_ON_ROUTE = 5, // old value: 5  ---> tracking starts
	DRIVER_ARRIVED_AT_CUSTOMER = 160, // old value: 105
	DRIVER_DELIVERED_THE_ORDER = 170, // old value: 6
	DRIVER_COLLECTED_THE_PAYMENT = 180, // old value: 7 // ! To be dropped
	// ! others
	COMPLETED = 190, // old value: 9
	CANCELLED = 191, // old value: 4
	FAILED = 192, // generic failures, old value: 10
	DISPUTED = 193, // dispute/"court-like" situation, // old value: 12
}

export enum EOrderDisputedStatus {
	DISPUTED = 'disputed',
	NOT_DISPUTED = 'not_disputed',
}

export enum EOrderPaymentStatus {
	NOT_PAID = 'not_paid',
	PAID = 'paid',
}

export enum EOrderPaymentMethods {
	CASH = 'cash',
	CREDIT_CARD = 'credit_card',
	KNET = 'knet',
	WALLET = 'wallet',
	MADA = 'mada',
	APPLE_PAY = 'apple_pay',
	UAE_CC = 'uae_cc',
	BANK_ACCOUNT = 'bank_account',
	PROMOTION = 'promotion',
	NO_PAYMENT_METHOD = 'no_payment_method',
}

export enum EOrderBookingTypes {
	INSTANT = 'instant',
	SCHEDULED = 'scheduled',
}

export enum EOrderWalletComplementAmountRate {
	NONE = 'none',
	PARTIAL = 'partial',
	FULL = 'full',
}

export enum EOrderType {
	SINGLE = 'single',
	GROUP = 'group',
	CORRECTION = 'correction',
}

// ! interfaces
export interface IOrderCustomer {
	id: number;
	name: string;
	contact_number: string;
}

export interface IOrderBranch {
	id: number;
	vendor_id: number;
	name: string;
	lat: string;
	lng: string;
	address: string;
	timezone: string;

	type: string;
	contact_number?: string;
	average_ratings?: number;

	min_order_delivery_time: number;
	max_order_delivery_time: number;
}

interface IOrderInfo {
	code: string;
	type: EOrderType;
	status: number;
	status_description: string;

	placed_at: string;
	booking_type: EOrderBookingTypes;
	pickup_time?: string;
	additional_info?: string;

	delivery_mode: EDeliveryMode;
	delivery_mode_description: string;
	delivery_mode_description_detailed: string;

	min_customer_delivery_eta_at: string;
	max_customer_delivery_eta_at: string;
}

interface IOrderDriver {
	id?: number;
	name?: string;
	image?: string;
	rating?: number;
	contact_number?: string;
}

interface IOrderDeliveryAddress {
	type: string;
	address: string;
	lat: string;
	lng: string;
}

interface IOrderPayment {
	total_item_cost: number;
	store_discount_amount: number;
	platform_discount_amount: number;
	expected_delivery_cost: number;
	delivery_cost: number;
	status: EOrderPaymentStatus;
	method: EOrderPaymentMethods;
	should_display_payment_method: boolean;
	currency_code: string;
	paid_at: string;
	tax_description?: string;
	grand_total: number;
	amount_payable: number;
	wallet_complement_amount: number;
	wallet_complement_amount_rate: EOrderWalletComplementAmountRate;
}

export interface IProductSelectedOptions {
	id: number;
	name: string;
	unit_price: number;
	quantity: number;
}

interface IProductSelectedVariant {
	id: number;
	name: string;
	options?: IProductSelectedOptions[];
	barcode?: string;
	sku?: string;
}

export interface IOrderProductDetails {
	id: string;
	name: string;
	special_request?: string;

	quantity: number;
	unit_price: number;

	variant: IProductSelectedVariant;

	sub_category_id: number;
	sub_category_name: string;

	category_id: number;
	category_name: string;
}

interface IOrderPromotionsUsed {
	platform: string;
	vendor: string;
}

export interface IOrder {
	id: number;
	customer: IOrderCustomer;
	store: IOrderBranch;
	info: IOrderInfo;
	driver?: IOrderDriver;
	delivery_address: IOrderDeliveryAddress;
	payment: IOrderPayment;
	promotions_used: IOrderPromotionsUsed;
	products?: IOrderProductDetails[];
	actions: IOrderActions;
}

export interface IOrderListItemResponse {
	id: IOrder['id'];
	info: {
		code: IOrderInfo['code'];
		type: IOrderInfo['type'];
		placed_at: IOrderInfo['placed_at'];
		status: IOrderInfo['status'];
	};
	store: {
		id: IOrderBranch['id'];
		name: IOrderBranch['name'];
		vendor_id: IOrderBranch['vendor_id'];
	};
	customer: {
		id: IOrderCustomer['id'];
	};
	payment: {
		grand_total: IOrderPayment['grand_total'];
	};
	driver?: {
		id: IOrderDriver['id'];
		name: IOrderDriver['name'];
	};
	actions: {
		can_show_order_type: IOrderActions['can_show_order_type'];
	};
}

export interface IOrderLog {
	status: string;
	user_id: number;
	user_name: string;
	user_role: EUserTypes;

	comment: string;
	created_at: string;
}

// ! crud
export interface IFetchOrderListRequestConfig {
	branchIdList: number[];
	statusList?: EOrderStatus[];
	start_date?: string;
	end_date?: string;
}

export interface IUpdateOrdersStatusConfig {
	status: number;
	comment?: string;
}

export interface IOrderActions {
	can_transition_to_new: boolean;
	can_prepare: boolean;
	can_mark_as_ready: boolean;
	can_complete: boolean;
	can_cancel: boolean;
	can_reject: boolean;
	can_ring: boolean;
	can_show_order_type: boolean;
}
