import { FC } from 'react';
import { DEFAULT_EMPTY_VALUE_PLACEHOLDER } from 'configs/common';
import { DisplayNameAndNameProps } from './types';
import { Flex, Typography as T } from 'antd';

export const DisplayNameAndNameAr: FC<DisplayNameAndNameProps> = ({
	name = DEFAULT_EMPTY_VALUE_PLACEHOLDER,
	nameAr = DEFAULT_EMPTY_VALUE_PLACEHOLDER,
}) => {
	const abbrAr = 'AR';
	const abbrEn = 'EN';

	return (
		<Flex
			vertical
			className='w-100'
			gap={4}
		>
			<T.Text ellipsis={{ tooltip: name }}>
				<T.Text type='secondary'>{abbrEn}:</T.Text> {name}
			</T.Text>

			<T.Text ellipsis={{ tooltip: nameAr }}>
				<T.Text type='secondary'>{abbrAr}:</T.Text> {nameAr}
			</T.Text>
		</Flex>
	);
};
